import httpRepository from '@core/repository/http';

import profileEntity from './profileEntity';

export const login = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Authentications/Login',
    method: 'post',
    payload,
    showError: false,
  });
};
export const resetToken = (payload: { refreshToken: string }) => {
  return httpRepository.execute({
    path: '/api/Authentications/Refresh',
    method: 'post',
    payload,
    showSuccess: false,
  });
};

export const getProfile = () => {
  return httpRepository.execute({
    path: '/api/UserProfile/me',
    showSuccess: false,
    convert: res => {
      return new profileEntity(res);
    },
  });
};
export const updateProfile = (payload: any) => {
  return httpRepository.execute({
    path: '/api/UserProfile/me',
    method: 'put',
    payload,
    config: {
      isFormData: true,
    },
    convert: res => {
      return new profileEntity(res);
    },
  });
};

export const recoveryPassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Passwords/Recovery',
    method: 'post',
    payload,
    showSuccess: false,
  });
};

export const verifyRecoveryCode = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Passwords/VerifyRecoveryCode',
    method: 'post',
    payload,
    showError: false,
    showSuccess: false,
  });
};
export const resetPassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Passwords/Reset',
    method: 'post',
    payload,
  });
};

export const changePassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Passwords/Change',
    method: 'put',
    payload,
  });
};
