export default {
  ApplicationsCreate: 'Tạo ứng dụng',
  ApplicationsDelete: 'Xóa ứng dụng',
  ApplicationsSort: 'Sắp xếp ứng dụng',
  ApplicationsUpdate: 'Cập nhật ứng dụng',
  ApplicationsView: 'Xem ứng dụng',
  ApplicationsViewDetail: 'Xem chi tiết ứng dụng',
  GroupsCreate: 'Tạo nhóm người dùng',
  GroupsDelete: 'Xóa nhóm người dùng',
  GroupsUpdate: 'Cập nhật nhóm người dùng',
  GroupsView: 'Xem nhóm người dùng',
  GroupsViewDetail: 'Xem chi tiết nhóm người dùng',

  UsersSystemsUpdate: 'Cập nhật tài khoản quản trị',
  UsersSystemsDelete: 'Xóa tài khoản quản trị',
  UsersSystemsCreate: 'Tạo tài khoản quản trị',
  UsersSystemsView: 'Xem tài khoản quản trị',
  UsersSystemsViewDetail: 'Xem chi tiết tài khoản quản trị',
  UsersUpdate: 'Cập nhật tài khoản truy cập',
  UsersView: 'Xem tài khoản truy cập',
  UsersViewDetail: 'Xem chi tiết tài khoản truy cập',
  UsersCreate: 'Tạo tài khoản truy cập',
  UsersDelete: 'Xóa tài khoản truy cập',

  'user.status.read': 'Lấy trạng thái hoạt động người dùng',
  'user.name.write': 'Chỉnh sửa họ và tên người dùng',
  'user.phone.read': 'Lấy số điện thoại người dùng',
  'user.id.read': 'Lấy mã định danh người dùng',
  'user.email.read': 'Lấy email người dùng',
  'user.address.read': 'Lấy địa chỉ người dùng',
  'user.name.read': 'Lấy họ và tên người dùng',
  'user.avatar.read': 'Lấy ảnh đại diện người dùng',
  'user.idcard.Read': 'Lấy CMND/CCCD người dùng',
  'user.username.read': 'Lấy tên đăng nhập người dùng',
  'user.profile': 'Lấy thông tin người dùng',
};
