import React from 'react';

import { IRouter } from '@routers/interface';

export const routerHomeNormal: IRouter = {
  path: '/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home-normal.name', //translate here for breadcrumb and sidebar
  masterLayout: { hideFooter: true, hideHeader: true, hideSideBar: true },
};
