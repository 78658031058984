import enUS from 'antd/lib/locale/en_US';

import activityLog from './activityLog';
import adminAccount from './adminAccount';
import auth from './auth';
import common from './common';
import pageError from './pageError';
import roles from './roles';
import server from './server';
import user from './user';
import userGroup from './userGroup';
import applications from './applications';
import documents from './documents';

export default {
  ...enUS,
  ...common,
  ...userGroup,
  ...activityLog,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...adminAccount,
  ...user,
  ...applications,
  ...documents,
};
