export default {
  'applications.name': 'Quản lý ứng dụng',
  'applications.title': 'Danh sách ứng dụng',
  'applications-info-field.title': 'Thông tin hiển thị:',
  'applications-info-field.languageCode': 'Ngôn ngữ:',
  'applications-info-field.displayName': 'Tên hiển thị:',
  'applications-info-field.file': 'Tài liệu HDSD:',
  'applications-info-field.redirectUris': 'Đường dẫn truy cập:',
  'applications-info-field.Regex': 'Regex:',
  'applications-info-field.Webhook': 'Webhook:',
  'applications-info-field.secret': 'Mã định danh:',
  'applications-info-field.securityKey': 'Mã bảo mật:',
  'applications-info-field.description': 'Mô tả:',
  'applications-info.title-left': 'Thông tin',
  'applications-info.title-right': 'Phân quyền phạm vi giữa các ứng dụng',
  'userAccess.title': 'Danh sách người dùng được truy cập hệ thống',
  'userAccess.table.username': 'Tên đăng nhập',
  'userAccess.table.fullName': 'Họ và tên',
  'userAccess.table.phoneNumber': 'Số điện thoại',
  'userAccess.table.email': 'Email',
  'userAccess.table.groups': 'Nhóm người dùng',
  'userAccess.table.status': 'Trạng thái',
  'addAndUpdate.name-update': 'Cập nhật ứng dụng',
  'addAndUpdate.name-add': 'Thêm ứng dụng',
  'addAndUpdate.info-page': 'Thông tin',
  'addAndUpdate.scope-page': 'Phạm vi',
  'addAndUpdate.language': 'Thêm ngôn ngữ',
  'addAndUpdate.btn-add': 'Lưu và tiếp tục',
  'application.name': 'Tên ứng dụng',
  'application.redirectUris': 'Đường dẫn truy cập',
  'application.logo': 'Logo',
  'application.regex': 'Regex',
  'application.webHook': 'Webhook',
  'application.clientId': 'Mã định danh',
  'application.credentials': 'Mã bảo mật',
  'application.description': 'Mô tả',
  'application.languageCode': 'Ngôn ngữ',
  'application.displayName': 'Tên hiển thị',
  'application.file': 'Tài liệu hướng dẫn (PDF)',
  'addAndUpdate.scope-validation': 'Vui lòng chọn phạm vi',
  'application.languageCode.pl': 'Vui lòng chọn ngôn ngữ',
  'application.displayName.pl': 'Vui lòng nhập tên ứng dụng',
  'application.filePDF.pl': 'Chọn file đính kèm',
  'applications.button.sortOrder': 'Sắp xếp',
  'applications.arrayAction.delete.title': 'Xác nhận xóa ứng dụng',
  'applications.arrayAction.delete.content':
    'Các ứng dụng được chọn sẽ bị xóa và không thể khôi phục. ',
  'applications-sort.title': 'Sắp xếp ứng dụng',
  'applications.arrayAction.cancel': 'Huỷ',
  'applications.button.addApplication': 'Thêm ứng dụng',
  'applications.arrayAction.cancel.title': 'Xác nhận huỷ',
  'applications.arrayAction.cancel.content': 'Các ứng dụng vừa sắp xếp sẽ bị huỷ!',
  'application.tab-1': 'Thông tin ứng dụng',
  'application.tab-2': 'Người dùng được truy cập',
};
