export default {
  'adminAccount.router': 'Administration account',
  'adminAccount.title': 'Account list',
  'adminAccount.userName': 'User name',
  'adminAccount.name': 'First and last name',
  'adminAccount.phoneNumber': 'Phone number',
  'adminAccount.email': 'Email',
  'adminAccount.status': 'Status',
  'adminAccount.create': 'Add new administrative account',
  'adminAccount.information': 'Administration account information',
  'adminAccount.update': 'Administration account update',
  'adminAccount.userFullName': 'First and last name',
  'adminAccount.userEmail': 'Email',
  'adminAccount.userAvatar': 'Choose avatar',
  'adminAccount.userPhone': 'Phone number',
  'adminAccount.identifierNumber': 'ID/CCCD',
  'adminAccount.address': 'Address',
  'adminAccount.isActive': 'Operating status',
  'adminAccount.status.active': 'Active',
  'adminAccount.status.notActive': 'Inactive',
  'adminAccount.delete.title': 'Confirm account deletion',
  'adminAccount.delete.content':
    'The selected area management accounts will be deleted and unable to recover.',
  'adminAccount.validator-userName': 'Please enter the username',
  'adminAccount.validatorUserName': 'Username in the wrong format',
  'adminAccount.validator-userFullName': 'Please enter the first and last name',
  'adminAccount.validatorUndefined': 'First and last name is not in the format',
  'adminAccount.validate-phone': 'Phone number improperly formatted',
  'adminAccount.permissions': 'Role permissions',
  'adminAccount.password': 'Password',
  'adminAccount.confirmPassword': 'Confirm password',
  'adminAccount.fullName': 'Full name',
};
