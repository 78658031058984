export default {
  'server.notFound': 'Does not exist',
  'server.networkError': 'Network error',
  'Server.AccountEmail.NotExists': 'Login information is incorrect',
  'Server.AddBeat.Success': 'More success',
  'Server.DeleteBeat.Success': 'Delete success',
  'Server.UpdateBeat.Success': 'Success update',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Wrong name username or password',
  'Mes.Users.Login.Successfully': 'Logged in successfully',
  'Mes.Classes.Year.MustHave2Characters': 'The school year must have 2 characters',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Class code must have 2 characters',
  'Mes.Classes.Add.Successfully': 'Add successful classroom',
  'Mes.Questions.Update.Successfully': 'Update the question of success',
  'Mes.Questions.Add.Successfully': 'Add successful questions',
  'Mes.Users.UpdateSuccessfully': 'Successful account information update',
  'Mes.Users.Add.Successfully': 'Add successful account',
  'Mes.Users.DeleteUsers.Successfully': 'Delete success',
  'Mes.Researchs.Update.Successfully': 'Update successful research',
  'Mes.Researchs.Add.Successfully': 'Add successful research',
  'Mes.Researchs.Delete.Successfully': 'Delete successful research',
  'server.data.success': 'Success',
  Successful: 'Success',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'The recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Successful password reset',
  'Mes.Profile.GetDetailSuccessfully': 'Get successful account information',
  'Mes.Profile.OldPassword.Wrong': 'The old password is not right',
  'Mes.Profile.ChangePasswordSuccessfully': 'Successful password update',
  'Mes.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Role.CreateSuccessfully': 'Add a success role',
  'Mes.Role.DeleteSuccessfully': 'Delete the role of success',

  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username available',
  'Mes.User.Required.Username': 'Username is mandatory',
  'Mes.User.NotWhiteSpace.Username': 'Username without spaces',
  'Mes.User.NotSpecialCharacter.Username':
    'The username does not contain special characters (except for signs. @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Username available',
  'Mes.User.Required.Email': 'Email is mandatory',
  'Mes.User.NotWhiteSpace.Email': 'Email without spaces',
  'Mes.User.OverLength.Name': 'The username does not exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid photo',
  'Mes.User.Invalid.Password': 'Invalid password',
  'Mes.User.Required.Password': 'Password is mandatory',
  'Mes.User.IsWrong.OldPassword': 'The old password is not right',
  'Mes.User.Invalid.OldPassword': 'Old password is not invalid',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'ID is mandatory',
  'Mes.User.Status.Required': 'Status is mandatory',
  'Mes.User.Status.Invalid': 'Invalid state',
  'Mes.User.Required.RecoveryCode': 'Recovery code is mandatory',
  'Mes.User.NotFound.RecoveryCode': 'User recovery code does not exist',
  'Mes.User.NotFound': 'Not found users',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.User.Blocked': 'User has been locked',
  'Mes.UserRefreshToken.Required.Token': 'Refreshing code is mandatory',
  'Mes.UserRefreshToken.WasUsed': 'Refreshed code has been used',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refreshing code',
  'Mes.UserRefreshToken.Expired.Token': 'Refreshed code has expired',
  'Mes.User.Login.Successfully': 'Logged in successfully',
  'Mes.User.NotFound.Email': "Can't find email",
  'Mes.OAuth.InvalidFormat.RedirectUri': 'Redirect Uri is invalid',
  'Mes.User.Required.Name': 'The username is mandatory',
};
