export default {
  'users.name': 'Họ và tên',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Họ và tên',
  'users.phoneNumber': 'Số điện thoại',
  'users.action': 'Hành động',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.password': 'Mật khẩu',
  'users.userType': 'Vai trò',
  'users.create': 'Tạo người dùng',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'users.citizenId': 'CMND/CCCD',
  'users.email': 'Email',

  'users.table.username': 'Tên đăng nhập',
  'users.table.name': 'Họ và tên',
  'users.table.type': 'Loại tài khoản',
  'users.table.role': 'Vai trò',
  'users.table.phoneNumber': 'Số điện thoại',
  'users.table.email': 'Email',
  'users.table.action': 'Hành động',

  /////// User
  'user.name': 'Người dùng',
  'user.list': 'Danh sách người dùng',
  'user.userName': 'Tên đăng nhập',
  'user.userFullName': 'Họ và tên',
  'user.userEmail': 'Email',
  'user.userPhone': 'Số điện thoại',
  'user.userAvatar': 'Hình đại diện',
  'user.userPassword': 'Mật khẩu',
  'users.modal.form.confirmPassWord': 'Xác nhận mật khẩu',
  'users.modal.form.roleId': 'Vai trò',
  'user.isActive': 'Trạng thái hoạt động',
  'users.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'users.modal.form.confirmPassWord.placeholder': 'Vui lòng nhập xác nhận mật khẩu',
  'users.modal.form.confirmPassWord.required': 'Hai mật khẩu bạn đã nhập không khớp',
  'user.validator-userName': 'Tên đăng nhập không được để trống',
  'user.validator-userFullName': 'Vui lòng nhập họ và tên',

  'users.isActive.id': '{status, select, 1 {Hoạt động} 0 {Ngưng hoạt động} other {--} }',
  'users.validate-password': 'Mật khẩu không đúng định dạng',
  'users.validate-phone': 'Số điện thoại không đúng định dạng',

  'users.status': 'Trạng thái',
  'users.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',

  'settings.name': 'Cài đặt hệ thống',
  'role.management': 'Quản lí vai trò',
  'users.title': 'Danh sách người dùng đã phân quyền',
  'users.tab.name': 'Người dùng',
  'user.filter.status': 'Trạng thái',
  'user.status.active': 'Đang hoạt động',
  'user.status.notActive': 'Ngưng hoạt động',
  'roles.placeholder.name': 'Vui lòng chọn vai trò',
  'userList.role.name': 'Vai trò',
  'users.havePermission': 'Đã phân quyền',
  'users.noPermission': 'Chưa phân quyền',
  'users.noPermission.title': 'Danh sách người dùng chưa phân quyền',
  'users.delete.title': 'Xác nhận xóa tài khoản?',
  'users.delete.content': 'Dữ liệu thông tin tài khoản này sẽ bị xóa.',
  'users.identifierNumber': 'CMND/CCCD',

  'systemAccount.name': 'Tài khoản truy cập',
  'systemAccount.title': 'Danh sách tài khoản',
  'users.group': 'Nhóm người dùng',
  'user.address': 'Địa chỉ',
  'user.group': 'Nhóm người dùng',
  'systemAccount.update': 'Cập nhật tài khoản truy cập',
  'systemAccount.information': 'Thông tin tài khoản truy cập',
  'systemAccount.create': 'Thêm tài khoản truy cập mới',
  'systemAccount.delete.title': 'Xác nhận xóa tài khoản',
  'systemAccount.delete.content':
    'Các tài khoản truy cập được chọn sẽ bị xóa và không thể khôi phục.',
  'users.validator-userName': 'Vui lòng nhập tên đăng nhập',
  'users.validatorUserName': 'Tên đăng nhập không đúng định dạng',
  'user.validatorUndefined': 'Họ và tên không đúng định dạng',
};
