import React from 'react';
import { Users } from 'react-feather';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

const mainRoute = '/user-group';

export const routerUserGroup: IRouter = {
  path: mainRoute,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'userGroup.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Users />,
  },
  permissionCode: PermissionEnum.GroupsView,
};

export const routerUserGroupAdd: IRouter = {
  path: `${mainRoute}/add`,
  loader: React.lazy(() => import('./component/PageAddAndEdit/UserGroupAddAndEdit')),
  exact: true,
  name: 'userGroupAdd.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.GroupsCreate,
};

export const routerUserGroupEdit: IRouter = {
  path: `${mainRoute}/edit/:id`,
  loader: React.lazy(() => import('./component/PageAddAndEdit/UserGroupAddAndEdit')),
  exact: true,
  name: 'userGroupEdit.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.GroupsUpdate,
};

export const routerUserGroupInfo: IRouter = {
  path: `${mainRoute}/info/:id`,
  loader: React.lazy(() => import('./component/PageInfo/UserGroupInfo')),
  exact: true,
  name: 'userGroupInfo.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.GroupsViewDetail,
};
