import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { imgAvatar, logo, LogoutIcon } from '@assets/images';
import store from '@core/store/redux';
import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import { removeProfile } from '@modules/authentication/profileStore';
import { privatePageNormal, privatePageSystem } from '@routers/mainRouter';
import { useAltaIntl } from '@shared/hook/useTranslate';

import { LogoutOutlined } from '@ant-design/icons';
import { UserType } from '@modules/authentication/profileEntity';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import { routerLogin } from '@view/Auth/Login/router';
import MenuCustom from './ItemMenu';
import { useSelector } from 'react-redux';
import { RootState } from '@modules';

const SiderComponent: React.FC<{
  className: string;
  setClassName: (className: string) => void;
}> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useAltaIntl();

  const { className, setClassName } = props;
  const [width, setWidth] = useState<string | number>();
  const [collapse, setCollapse] = useState<boolean>(false);
  const refMouseEnter = useRef<any>();

  const onClick = (e: any) => {
    setClassName('sider-component big');
    e.preventDefault();
    e.stopPropagation();
    setCollapse(false);
  };
  const { user } = useSelector((state: RootState) => state.profile);

  const handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    if (collapse === false) {
      setClassName('sider-component');
    } else {
      setClassName('sider-component big');
      setCollapse(false);
    }
    e.stopPropagation();
  };

  const onMouseEnter = e => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    refMouseEnter.current = setTimeout(() => {
      onClick(e);
    }, 800);
  };

  const onMouseLeave = () => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
  };

  useEffect(() => {
    if (className === 'sider-component') {
      setWidth('30rem');
      setCollapse(true);
    } else {
      setWidth('100%');
    }
  }, [className]);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        <div
          className="icon"
          onClick={handleOnClick}
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {collapse ? <UilAngleRight /> : <UilAngleLeft />}
        </div>
      }

      <div className="mask" style={{ width }}>
        <div className="wrapper-logo">
          <div className={`logo ${collapse ? 'logo-small' : ''}`}>
            <img src={logo} alt="logo" onClick={() => navigate('/')} />
          </div>
        </div>
        <div className="wrapper-sidebar">
          <MenuCustom
            listNav={user?.userType === UserType.USER_SSO ? privatePageSystem : privatePageNormal}
            location={location.pathname}
            collapse={collapse}
          />
        </div>
      </div>
    </div>
  );
};

export default SiderComponent;
