import React from 'react';
import { Clock } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerActivityLog: IRouter = {
  path: '/activity-log',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'activityLog.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <Clock />,
  },
};
