export default {
  'profile.role': 'Vai trò',
  'profile._password': 'Mật khẩu',
  'profile.change.password.title': 'Thay đổi mật khẩu',
  'profile.newPassword': 'Mật khẩu mới',
  'profile.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'password.not.match': 'Mật khẩu không khớp',
  'profile.name': 'Hồ sơ',
  'profile.oldPassword': 'Mật khẩu cũ',
  'profile.title': 'Thông tin cơ bản',
  'users.nfcCode': 'CMND/CCCD',
  'profile.edit': 'Chỉnh sửa hồ sơ',
  'profile.back': 'Quay lại',
};
