export default {
  'applications.name': 'Application management',
  'applications.title': 'List of applications',
  'applications-info-field.title': 'Display Information:',
  'applications-info-field.languageCode': 'Language:',
  'applications-info-field.displayName': 'Display Name:',
  'applications-info-field.file': 'User Guide:',
  'applications-info-field.redirectUris': 'Access Paths:',
  'applications-info-field.Regex': 'Regex:',
  'applications-info-field.Webhook': 'Webhook:',
  'applications-info-field.secret': 'Identifier Code:',
  'applications-info-field.securityKey': 'Security Code:',
  'applications-info-field.description': 'Description:',
  'applications-info.title-left': 'Information',
  'applications-info.title-right': 'Permission scope between applications',
  'userAccess.title': 'List of users accessing the system',
  'userAccess.table.username': 'Username',
  'userAccess.table.fullName': 'Full Name',
  'userAccess.table.phoneNumber': 'Phone Number',
  'userAccess.table.email': 'Email',
  'userAccess.table.groups': 'User Groups',
  'userAccess.table.status': 'Status',
  'addAndUpdate.name-update': 'Update Application',
  'addAndUpdate.name-add': 'Add Application',
  'addAndUpdate.info-page': 'Information',
  'addAndUpdate.scope-page': 'Scope',
  'addAndUpdate.language': 'Add Language',
  'addAndUpdate.btn-add': 'Save and Continue',
  'application.name': 'Application Name',
  'application.redirectUris': 'Access Paths',
  'application.logo': 'Logo',
  'application.regex': 'Regex',
  'application.webHook': 'Webhook',
  'application.clientId': 'Identifier Code',
  'application.credentials': 'Security Code',
  'application.description': 'Description',
  'application.languageCode': 'Language',
  'application.displayName': 'Display Name',
  'application.file': 'Instruction Document (PDF)',
  'addAndUpdate.scope-validation': 'Please select the scope',
  'application.languageCode.pl': 'Please select the language',
  'application.displayName.pl': 'Please enter the application name',
  'application.filePDF.pl': 'Choose attached file',
  'applications.button.sortOrder': 'Sort',
  'applications.arrayAction.delete.title': 'Confirm Delete Application',
  'applications.arrayAction.delete.content':
    'The selected applications will be deleted and cannot be recovered.',
  'applications-sort.title': 'Sort Applications',
  'applications.arrayAction.cancel': 'Cancel',
  'applications.button.addApplication': 'Add Application',
  'applications.arrayAction.cancel.title': 'Confirm Cancel',
  'applications.arrayAction.cancel.content': 'The recently sorted applications will be canceled!',
  'application.tab-1': 'Application information',
  'application.tab-2': 'Users are accessed',
};
