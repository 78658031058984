export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',

  'Mes.User.Invalid.Username': 'Tên đăng nhập không hợp lệ',
  'Mes.User.AlreadyExist.Username': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Username': 'Tên đăng nhập là bắt buộc',
  'Mes.User.NotWhiteSpace.Username': 'Tên đăng nhập không có khoảng trắng',
  'Mes.User.NotSpecialCharacter.Username': 'Tên đăng nhập không chứa ký tự đặc biệt (trừ dấu . @)',
  'Mes.User.Invalid.Email': 'Email không hợp lệ',
  'Mes.User.AlreadyExist.Email': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Email': 'Email là bắt buộc',
  'Mes.User.NotWhiteSpace.Email': 'Email không có khoảng trắng',
  'Mes.User.OverLength.Name': 'Tên người dùng không vượt quá 250 kí tự',
  'Mes.User.Invalid.AvatarPicture': 'Ảnh đại diện không hợp lệ',
  'Mes.User.Invalid.Password': 'Mật khẩu không hợp lệ',
  'Mes.User.Required.Password': 'Mật khẩu là bắt buộc',
  'Mes.User.IsWrong.OldPassword': 'Mật khẩu cũ không đúng',
  'Mes.User.Invalid.OldPassword': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Invalid.PhoneNumber': 'Số điện thoại không hợp lệ',
  'Mes.User.Required.RoleId': 'Id vai trò là bắt buộc',
  'Mes.User.Status.Required': 'Trạng thái là bắt buộc',
  'Mes.User.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.User.Required.RecoveryCode': 'Mã khôi phục là bắt buộc',
  'Mes.User.NotFound.RecoveryCode': 'Mã khôi phục người dùng không tồn tại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.PleaseCheckEmail': 'Hãy kiểm tra lại email của bạn',
  'Mes.User.Blocked': 'Người dùng đã bị khóa',
  'Mes.UserRefreshToken.Required.Token': 'Mã làm mới là bắt buộc',
  'Mes.UserRefreshToken.WasUsed': 'Mã làm mới đã được sử dụng',
  'Mes.UserRefreshToken.Invalid.Token': 'Mã làm mới không hợp lệ',
  'Mes.UserRefreshToken.Expired.Token': 'Mã làm mới đã hết hạn',
  'Mes.User.Login.Successfully': 'Đăng nhập thành công',
  'Mes.User.NotFound.Email': 'Không tìm thấy email',
  'Mes.OAuth.InvalidFormat.RedirectUri': 'Redirect URI không hợp lệ',
  'Mes.User.Required.Name': 'Tên người dùng là bắt buộc',
};
