import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, matchPath, useLocation } from 'react-router-dom';

import DefaultLayout from '@layout/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { TokenSelector } from '@modules/authentication/profileStore';
import { privatePageNormal, privatePageSystem } from '@routers/mainRouter';
import { useSingleAsync } from '@shared/hook/useAsync';

import { UserType } from '@modules/authentication/profileEntity';
import { IRouter } from '@routers/interface';
import useRouter from './useRouter';

const PrivatePage: React.FC = () => {
  const token = useSelector(TokenSelector);
  const location = useLocation();
  const getProfile = useSingleAsync(authenticationPresenter.getProfile);
  const [privatePage, setPrivatePage] = useState<IRouter[]>([]);

  useEffect(() => {
    if (token) {
      getProfile.execute().then(res => {
        setPrivatePage(
          res.userType === UserType.USER_NORMAL ? privatePageNormal : privatePageSystem,
        );
      });
    }
  }, [token]);

  const { views, routes } = useRouter({
    routers: privatePage,
    privateRoute: true,
  });

  const showMasterLayout = React.useMemo(() => {
    const r = routes.find(it => matchPath(it.path, location.pathname));
    if (r === undefined) {
      return { hideSideBar: true, hideHeader: true };
    }
    if (r?.masterLayout == null) {
      return { hideSideBar: false, hideHeader: false };
    } else if (r?.masterLayout === false) {
      return { hideSideBar: true, hideHeader: true };
    } else if (typeof r?.masterLayout === 'object') {
      return {
        hideSideBar: r?.masterLayout?.hideSideBar ? true : false,
        hideHeader: r?.masterLayout?.hideHeader ? true : false,
        hideFooter: r?.masterLayout?.hideFooter ? true : false,
      };
    }
    return { hideSideBar: false, hideHeader: false };
  }, [location.pathname, routes]);

  return (
    <DefaultLayout
      hideSideBar={showMasterLayout.hideSideBar}
      hideHeader={showMasterLayout.hideHeader}
      hideFooter={showMasterLayout.hideFooter}
      loading={getProfile.status !== 'ready'}
    >
      <Routes>{views}</Routes>
    </DefaultLayout>
  );
};
export default React.memo(PrivatePage);
