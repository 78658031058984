import { ELanguage } from '@modules/languages/languagesEntity';
import dayjs, { Dayjs } from 'dayjs';
import lodash, { isEmpty } from 'lodash';

export const indexOfArrayObject = (array: any[], key: string, value: any) => {
  if (!Array.isArray(array)) {
    return;
  }
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key] === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const debounce = (callback: any, delay: number) => {
  return lodash.debounce(callback, delay);
};

export const onScrollBottom = (callBack: any) => {
  window.onscroll = function (event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      callBack(event);
    }
  };
};

export function roundToTwo(num: string) {
  return Number.parseFloat(num).toFixed(2);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const random = Math.random();
  return Math.floor(random * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
  return [...arr].splice(start, end);
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie: string = decodeURIComponent(document.cookie) || '';
  if (decodedCookie == null || decodedCookie === '') {
    return '';
  }
  const cookieValue = decodedCookie
    .split('; ')
    ?.find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue || '';
};

export const toFirstUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const toFirstLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function sessionStorageSetItem(key: string, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function sessionStorageGetItem(key: string) {
  const dataJson = sessionStorage.getItem(key);
  if (dataJson != null) {
    return JSON.parse(dataJson);
  } else {
    return undefined;
  }
}
export const removeNullFields = values => {
  for (const key in values) {
    if (values[key] === null) {
      values[key] = undefined;
    }
  }
  return values;
};

export const delay = (milliSecond: any) => new Promise(resolve => setTimeout(resolve, milliSecond));
export const dateToString = (date: any, format: string = 'DD/MM/YYYY') => {
  if (!dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format(format);
};
export const convertApplicableTime = (
  applicableTimeFrom: string | undefined,
  applicableTimeTo: string | undefined,
  display: boolean = true,
) => {
  if (!display) {
    return ' ';
  }
  if (applicableTimeFrom && applicableTimeTo) {
    return `${dateToString(applicableTimeFrom)} - ${dateToString(applicableTimeTo)}`;
  }
  return 'tariff.info.isLimited-no';
};
export function formatVNDCurrency(money) {
  if (isNaN(money)) {
    return ' ';
  }
  money = Math.round(money / 1000) * 1000;
  const numberString = String(money);
  const regex = /(\d)(?=(\d{3})+$)/g;
  return numberString.replace(regex, '$1.') + ' VND';
}

export function getMeta(url, callback) {
  const img = new Image();
  img.src = url;
  img.onload = function (this, ev) {
    callback(img.width, img.height);
  };
}
export async function getThumbnailForVideo(videoUrl) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  // Trigger video load
  await new Promise<void>((resolve, reject) => {
    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener('seeked', () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas?.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL('image/png');
  return imageUrl;
}

export const handleInputNumber = e => {
  if (e.key === '.' || e.key === ',') {
    e?.preventDefault();
  }
};
//take in a number of second, return string formatted 'hh:mm:ss'

export const secondToDuration = (seconds: number) => {
  if (!(seconds > 0)) {
    return '';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Format the values as two digits with leading zeros if necessary
  const formattedHours = ('0' + hours).slice(-2);
  const formattedMinutes = ('0' + minutes).slice(-2);
  const formattedSeconds = ('0' + remainingSeconds).slice(-2);

  return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
};
//take in a dayjs, return the diff from start of date to that moment in second
export const calculateDuration = (value: Dayjs | null): number => {
  if (!value) {
    return 0;
  }
  //input is dayjs object
  const startOfDay = value.startOf('date');
  const secondsUntilStartOfDay = value.diff(startOfDay, 'second');
  return secondsUntilStartOfDay;
};
//take in array of object {duration: number}, return string formatted 'hh:mm:ss'
export const calcSumDuration = (list: any): string => {
  if (lodash.isEmpty(list) || !lodash.isArray(list)) {
    return '00:00:00';
  }
  //input is dayjs object
  const sum = list.reduce((total, it) => {
    return total + it.duration || 0;
  }, 0);
  return secondToDuration(sum);
};
export function roundStringNumber(inputString, decimalPlaces) {
  if (inputString == null) return '';
  const decimalIndex = inputString.indexOf('.');
  if (decimalIndex !== -1) {
    const roundedString = inputString.substring(0, decimalIndex + decimalPlaces + 1);
    return roundedString;
  } else {
    return inputString;
  }
}

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const toTimeFormat = (milliseconds: number) => {
  return dayjs.duration(milliseconds).format('HH:mm:ss');
};

export function cutLink(value) {
  if (!value) {
    return;
  }
  return value.split('/')[value.split('/').length - 1].split('?')[0];
}

export const findDataByLanguage = (arr, language) => {
  if (isEmpty(arr)) return;
  if (language === 'vi') {
    return arr.find(it => it.languageCode === ELanguage.vi);
  } else {
    return arr.find(it => it.languageCode === ELanguage.en);
  }
};
