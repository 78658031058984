export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const img404 = require('./404.png');
export const error = require('./error.png');

export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';
export { default as audioThumbnail } from './audio-thumbnail.jpg';
export { default as defaultMedia } from './dummy.jpg';
export { default as ImgLogout } from './img-logout.png';
export { default as ImgLogoSSO } from './logo-sso.png';
export { default as loginBg } from './login_bg.png';
