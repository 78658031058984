import { routerActivityLog } from '@view/ActivityLog/router';
import { routerAdminAccount } from '@view/AdminAccount/router';
import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';
import { routerSystemAccount } from '@view/SystemAccount/router';
import {
  routerUserGroup,
  routerUserGroupAdd,
  routerUserGroupEdit,
  routerUserGroupInfo,
} from '@view/UserGroup/router';

import {
  routerApplications,
  routerApplicationsAdd,
  routerApplicationsEdit,
  routerApplicationsInfo,
  routerApplicationsSort,
} from '@view/Applications/router';
import { routerDocuments } from '@view/Documents/router';
import { routerHomeNormal } from '@view/HomeNormal/router';
import { IRouter } from './interface';
import { routerListApplication } from '@view/ListApplication/router';

export const privatePageNormal: IRouter[] = [
  routerDocuments,
  routerHomeNormal,
  routerListApplication,
  routerViewProfile,
  routerPageError,
];

export const privatePageSystem: IRouter[] = [
  routerHome,
  routerApplications,
  routerApplicationsInfo,
  routerApplicationsAdd,
  routerApplicationsEdit,
  routerApplicationsSort,
  routerAdminAccount,
  routerSystemAccount,
  routerUserGroup,
  routerUserGroupAdd,
  routerUserGroupEdit,
  routerUserGroupInfo,
  routerActivityLog,
  routerViewProfile,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerForgotPassword,
  routerResetPassword,
  routerLogin,
  routerPageError,
];
