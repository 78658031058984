import React from 'react';

import { FileUserIcon } from '@assets/icon/fileUserIcon';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerSystemAccount: IRouter = {
  path: '/system-account',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'systemAccount.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <FileUserIcon />,
  },
  permissionCode: PermissionEnum.UsersView,
};
