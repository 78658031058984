export default {
  'users.name': 'Full name',
  'users.userName': 'User name',
  'users.fullName': 'Full name',
  'users.phoneNumber': 'Phone number',
  'users.action': 'Action',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.password': 'Password',
  'users.userType': 'Role',
  'users.create': 'Create user',
  'users.delete': 'Delete user',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'users.citizenId': 'ID/CCCD number',
  'users.email': 'Email',

  'users.table.username': 'user name',
  'users.table.name': 'Full name',
  'users.table.type': 'Account type',
  'users.table.role': 'Role',
  'users.table.phoneNumber': 'Phone number',
  'users.table.email': 'Email',
  'users.table.action': 'Act',

  /////// User
  'user.name': 'User',
  'user.list': 'User list',
  'user.userName': 'Name',
  'user.userFullName': 'Full name',
  'user.userEmail': 'Email',
  'user.userPhone': 'Phone number',
  'user.userAvatar': 'Avatar',
  'user.userPassword': 'Password',
  'users.modal.form.confirmPassWord': 'Confirm password',
  'users.modal.form.roleId': 'Role',
  'user.isActive': 'Operating status',
  'users.modal.form.password.placeholder': 'Please enter a password',
  'users.modal.form.confirmPassWord.placeholder': 'Please enter the password confirmation',
  'users.modal.form.confirmPassWord.required':
    'The two passwords you have entered without matching',
  'user.validator-userName': 'The username is not allowed to leave the blank',
  'user.validator-userFullName': 'Please enter the fullname',

  'users.validate-password': 'The password is incorrectly formatted',
  'users.validate-phone': 'Phone number improperly formatted',

  'users.status': 'Status',
  'users.status.param': '{status,select, 1 {Active} 2 {Inactive} other {Unknown}}',

  'settings.name': 'System installation',
  'role.management': 'Role management',
  'users.title': 'List of user decentralized',
  'users.tab.name': 'User',
  'user.filter.status': 'Status',
  'user.status.active': 'Active',
  'user.status.notActive': 'Inactive',
  'roles.placeholder.name': 'Please choose the role',
  'userList.role.name': 'Role',
  'users.havePermission': 'Decentralized',
  'users.noPermission': 'Unauthorized',
  'users.noPermission.title': 'List of users have not decentralized',
  'users.delete.title': 'Confirm account deletion?',
  'users.delete.content': 'This account information data will be deleted.',
  'users.identifierNumber': 'ID/CCCD number',

  'systemAccount.name': 'Access account',
  'systemAccount.title': 'Account list',
  'users.group': 'User group',
  'user.address': 'Address',
  'user.group': 'User group',
  'systemAccount.update': 'Update access accounts',
  'systemAccount.information': 'Accessed account information',
  'systemAccount.create': 'Add new access accounts',
  'systemAccount.delete.title': 'Confirm account deletion',
  'systemAccount.delete.content':
    'The selected access accounts will be deleted and unable to recover.',
  'users.validator-userName': 'Please enter the username',
  'users.validatorUserName': 'Username in the wrong format',
  'user.validatorUndefined': 'Full name is not in the format',
};