import React from 'react';

import { IRouter } from '@routers/interface';
import { Command } from 'react-feather';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerApplications: IRouter = {
  path: '/applications',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'applications.name',
  masterLayout: true,
  menu: {
    icon: <Command />,
  },
  permissionCode: PermissionEnum.ApplicationsView,
};

export const routerApplicationsInfo: IRouter = {
  path: '/applications/info/:id',
  loader: React.lazy(() => import('./Pages/Info')),
  exact: true,
  name: 'applications.info',
  masterLayout: true,
  permissionCode: PermissionEnum.ApplicationsViewDetail,
  generatePath(id: string, listPermissions: any) {
    const permissionTabs = [PermissionEnum.ApplicationsViewDetail];
    if (permissionTabs.find(item => listPermissions.includes(item))) {
      return `/applications/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}`;
    }
    return `/applications/info/${id}?tab=${PermissionEnum.ApplicationsViewDetail}`;
  },
};
export const routerApplicationsAdd: IRouter = {
  path: '/applications/add',
  loader: React.lazy(() => import('./Pages/AddAndUpdate')),
  exact: true,
  name: 'applications.add',
  masterLayout: true,
  permissionCode: PermissionEnum.ApplicationsCreate,
};
export const routerApplicationsEdit: IRouter = {
  path: '/applications/:id',
  loader: React.lazy(() => import('./Pages/AddAndUpdate')),
  exact: true,
  name: 'applications.edit',
  masterLayout: true,
  permissionCode: PermissionEnum.ApplicationsUpdate,
};
export const routerApplicationsSort: IRouter = {
  path: '/applications/sort',
  loader: React.lazy(() => import('./Pages/CardSort')),
  exact: true,
  name: 'applications.sort',
  masterLayout: true,
  permissionCode: PermissionEnum.ApplicationsSort,
};
