import React from 'react';

import { IRouter } from '@routers/interface';
import { FacilityIcon } from '@assets/icon/facilityIcon';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerAdminAccount: IRouter = {
  path: '/admin-account',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'adminAccount.router', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <FacilityIcon />,
  },
  permissionCode: PermissionEnum.UsersSystemsView,
};
