export enum ELanguage {
  en = 'en-US',
  vi = 'vi-VN',
}

class LanguagesEntity {
  //copy props from backend:
  code = '';

  name = '';

  label = '';

  value = '';

  constructor(languages: Partial<LanguagesEntity>) {
    if (!languages) {
      return;
    }
    Object.assign(this, languages);
    // convert entity type here
    this.label = languages.name || '';
    this.value = languages.code || '';
  }

  static createListLanguages(listLanguages: Array<Partial<LanguagesEntity>>) {
    if (!Array.isArray(listLanguages)) {
      return [];
    }
    return listLanguages.map((languages: Partial<LanguagesEntity>) => {
      return new LanguagesEntity(languages);
    });
  }
}
export default LanguagesEntity;
