import { Locale } from '@locale/index';
import { RootState } from '@modules';
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';

interface IStore {
  language: string;
  toggle: boolean;
}

const settingStore = createSlice({
  name: 'settingStore',
  initialState: {
    language: 'vi',
    toggle: false,
  } as IStore,
  reducers: {
    updateLanguage: (state, action: PayloadAction<keyof Locale>) =>
      Object.assign(state, { language: action.payload }),
    toggle: (state, action: PayloadAction<boolean>) =>
      Object.assign(state, { toggle: action.payload }),
  },
});

interface ILang {
  language: string;
}
export const LanguageSelector: Selector<RootState, ILang> = state => {
  return {
    language: state.settingStore.language,
  };
};
export const ToggleSelector: Selector<RootState, boolean> = state =>
  state.settingStore.toggle || false;
export default settingStore;
